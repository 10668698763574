import { MicrowdAPI } from "@/firebase";
import axios from "axios";

// _____________________________________ STATE _____________________________________
const getDefaultState = () => {
  return {
    borrower_data: {},
    creditHistory: [],
    newBorrowerDocumentIdentity: ""
  };
};
const state = getDefaultState();

// _____________________________________ ACTIONS _____________________________________
const actions = {
  async createBorrower(_, newBorrowerObj) {
    const response = await MicrowdAPI({ action: "createBorrower", data: newBorrowerObj });
    return response.data;
  },

  // Check if borrower exists by her identity document
  async checkBorrowerExists({ commit, dispatch }, identity_document) {
    commit("RESET_STATE");

    try {
      const response = await axios({
        method: "post",
        url: `${process.env.VUE_APP_ELASTIC_URL}/api/borrower`,
        headers: { ELASTIC_SERVICE_SECRET_KEY: "secret" },
        data: { filter: { identity_document } }
      });

      if (response.data.total > 0) {
        commit("SET_BORROWER_DATA", { data: response.data.data[0], identity_document });
        await dispatch("checkBorrowerCreditHistory", response.data.data[0].id);
      }
    } catch (error) {
      console.log(error);
    }
  },

  // Check the history of borrower credits
  async checkBorrowerCreditHistory({ commit }, borrower_id) {
    const response = await MicrowdAPI({
      action: "checkBorrowerCreditHistory",
      data: borrower_id
    });

    commit("SET_CREDIT_HISTORY", response.data.data);
  }
};

// _____________________________________ GETTERS _____________________________________
const getters = {
  getBorrowerId: (state) => {
    return state.borrower_data.id;
  },

  getBorrowerCountry: (state) => {
    return state.borrower_data.country;
  }
};

// _____________________________________ MUTATIONS _____________________________________
const mutations = {
  SET_BORROWER_DATA(state, { data, identity_document }) {
    state.borrower_data = data;
    state.borrower_data.identity_document = identity_document;
  },

  SET_CREDIT_HISTORY(state, data) {
    state.creditHistory = data;
  },

  SET_NEW_BORROWER_IDENTITY_DOCUMENT(state, identity_document) {
    state.newBorrowerDocumentIdentity = identity_document;
  },

  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  }
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
