export default {
  civils_status: [
    {
      text: "Casada",
      value: "MARRIED"
    },
    {
      text: "Soltera",
      value: "SINGLE"
    },
    {
      text: "Divorciada",
      value: "DIVORCEE"
    },
    {
      text: "Viuda",
      value: "WIDOW"
    },
    {
      text: "Unión Libre",
      value: "FREE_UNION"
    },
    {
      text: "Separada",
      value: "SEPARATED"
    }
  ],
  loan_types: [
    {
      text: "Bianual",
      value: "BIANNUAL"
    },
    {
      text: "Anual",
      value: "ANNUAL"
    }
  ],
  phone_types: [
    {
      text: "Un celular inteligente: con acceso a internet y aplicaciones como WhatsApp o Facebook",
      value: "SMARTPHONE"
    },
    {
      text: "Un celular sin internet: sin acceso a internet ni aplicaciones",
      value: "OLD_PHONE"
    }
  ],
  living_places: [
    {
      text: "Propia",
      value: "YOUR_OWN"
    },
    {
      text: "Pago renta",
      value: "RENTED"
    }
  ],
  savings_targets: [
    {
      text: "Imprevistos",
      value: "UNFORESEEN"
    },
    {
      text: "Mejora de vida de hijos y familia",
      value: "IMPROVE_LIFE"
    },
    {
      text: "Salud",
      value: "HEALTH"
    },
    {
      text: "No ahorro",
      value: "NOT_SAVE"
    },
    {
      text: "Objetivos personales",
      value: "PERSONAL_OBJECTIVES"
    },
    {
      text: "Reinvertir en negocios",
      value: "REINVEST_BUSINESS"
    },
    {
      text: "Otros",
      value: "OTHERS"
    }
  ],
  business_objetives: [
    {
      text: "Negocio ya existente",
      value: "IMPROVE_EXISTING_BUSINESS"
    },
    {
      text: "Comenzar uno nuevo",
      value: "NEW_BUSINESS"
    }
  ],
  payments_delays: [
    {
      text: "No tiene",
      value: "NOT"
    },
    {
      text: "Tiene 3 o menos",
      value: "THREE_OR_LESS"
    },
    {
      text: "Tiene más de 3",
      value: "MORE_THAN_THREE"
    },
    {
      text: "Tiene una deuda no pagada",
      value: "UNPAID_DEBT"
    }
  ],
  have_job: [
    {
      text: "Usted y su familia",
      value: "HER_AND_FAMILY"
    },
    {
      text: "Usted",
      value: "HER"
    },
    {
      text: "Su marido y sus hijos",
      value: "HUSBAND_AND_CHILDREN"
    },
    {
      text: "Ni usted ni su familia",
      value: "NONE"
    }
  ],
  accounts_control: [
    {
      text: "Controlo mis cuentas cada día",
      value: "EVERY_DAY"
    },
    {
      text: "Controlo mis cuentas una vez a la semana",
      value: "ONCE_WEEK"
    },
    {
      text: "Controlo mis cuentas una vez al mes",
      value: "ONCE_MONTH"
    },
    {
      text: "Alguien de mi familia lleva el control",
      value: "ANOTHER_PERSON"
    },
    {
      text: "Ni yo ni mi familia controlamos las cuentas",
      value: "NO_ONE"
    }
  ],
  opinions_borrower: [
    {
      text: "Es una persona respetada, en la que se puede confiar",
      value: 0
    },
    {
      text: "Se puede confiar en ella, pero no todo el mundo le prestaría",
      value: 1
    },
    {
      text: "No puede confiar al 100% en ella, habría que conocerla bien antes",
      value: 2
    },
    {
      text: "No se puede confiar en ella",
      value: 3
    }
  ],
  recommended_borrower: [
    {
      text: "Sí, una mujer extraordinaria Microwd al corriente de pagos",
      value: 0
    },
    {
      text: "Sí, una persona ajena a Microwd",
      value: 1
    },
    {
      text: "Sí, una mujer Microwd que tiene o ha tenido retrasos",
      value: 2
    },
    {
      text: "No",
      value: 3
    }
  ],
  know_more_answers: [
    {
      text: "Totalmente de acuerdo",
      value: 0
    },
    {
      text: "Algo de acuerdo",
      value: 1
    },
    {
      text: "Ni de acuerdo ni en desacuerdo",
      value: 2
    },
    {
      text: "Algo en desacuerdo",
      value: 3
    },
    {
      text: "Totalmente en desacuerdo",
      value: 4
    }
  ],
  payment_capacity_answers: [
    {
      text: "Más de un 300% de la cuota",
      value: 0
    },
    {
      text: "Entre un 100% y un 300% de la cuota",
      value: 1
    },
    {
      text: "Entre un 50% y un 100% de la cuota",
      value: 2
    },
    {
      text: "Menos de un 50% de la cuota",
      value: 3
    }
  ],
  know_business_answers: [
    {
      text: "Conoce aproximadamente la cantidad que va a generar",
      value: 0
    },
    {
      text: "Intuye vagamente cuántos ingresos tendrá",
      value: 1
    },
    {
      text: "No es capaz de saber cuántos ingresos tendrá",
      value: 2
    }
  ]
};
