import Vue from "vue";
import Vuex from "vuex";
import common from "@/modules/common";
import borrower from "@/modules/borrower";
import request from "@/modules/request";
import { uploadToStorage } from "@/firebase";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    loading: false,
    snackbar: {
      show: false,
      text: "",
      color: ""
    }
  },
  actions: {
    async uploadFileToStorage({ commit }, { file, child }) {
      await uploadToStorage({
        file,
        child
      });

      commit("SET_SNACKBAR", { show: true, text: "Image uploaded successfully", color: "success" });
    }
  },
  mutations: {
    SET_LOADING(state, bool) {
      state.loading = bool;
    },

    SET_SNACKBAR(state, params) {
      state.snackbar.show = params.show;
      state.snackbar.text = params.text;
      state.snackbar.color = params.color;
    }
  },
  modules: {
    borrower,
    common,
    request
  }
});

export default store;
