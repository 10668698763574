<template>
  <v-overlay v-if="loading" z-index="301" class="text-center">
    <v-progress-circular indeterminate color="#00f9ad" />
  </v-overlay>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["loading"])
  }
};
</script>
