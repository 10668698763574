<template>
  <v-app>
    <Loader />
    <Snackbar />
    <div>
      <v-app-bar color="transparent" elevation="0" height="150" dark class="black--text">
        <v-row class="max-width-700 mx-md-auto">
          <v-col cols="12" md="6">
            <h1>|MICROWD.</h1>
            <v-btn v-if="$route.path !== '/'" text small class="black--text ml-n4" @click="reload()">
              <v-icon class="pa-0">arrow_left</v-icon>
              <u>Salir</u>
            </v-btn>
          </v-col>
          <!-- <v-col cols="12" md="6">
            <p class="text-md-right mb-0 font-weight-bold">¿TIENE DUDAS?</p>
            <div class="d-flex align-center justify-md-end text-subtitle-2">
              <img width="18" height="18" class="mr-2" alt="WhatsApp Logo" src="@/assets/logos/WhatsApp_logo.png" />
              <p class="mb-0">
                Escríbanos por
                <span class="font-weight-bold"><a href="https://wa.me/5548323493" class="black--text">WhatsApp a 5548323493</a></span>
              </p>
            </div>
          </v-col> -->
        </v-row>
      </v-app-bar>
    </div>

    <v-main>
      <v-container fill-height fluid class="max-width-700">
        <v-row justify="center">
          <v-col cols="12">
            <router-view />
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <Footer />
  </v-app>
</template>

<script>
import Loader from "@/components/ui/Loader";
import Snackbar from "@/components/ui/Snackbar";
import Footer from "@/components/ui/Footer";

export default {
  name: "App",
  components: {
    Loader,
    Snackbar,
    Footer
  },
  methods: {
    reload() {
      this.$store.commit("borrower/RESET_STATE");
      this.$store.commit("request/RESET_STATE");
      this.$router.push("/");
    }
  }
};
</script>

<style>
@import "./assets/css/main.css";
</style>
