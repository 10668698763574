import { MicrowdAPI } from "@/firebase";
import form_selects_values from "./form_selects_values";

// _____________________________________ STATE _____________________________________
const getDefaultState = () => {
  return {
    id: "",
    form_selects_values
  };
};
const state = getDefaultState();

// _____________________________________ ACTIONS _____________________________________
const actions = {
  async createRequest({ commit, rootGetters }, office_id) {
    const borrower_id = rootGetters["borrower/getBorrowerId"];
    const response = await MicrowdAPI({
      action: "createRequest",
      data: { office_id, borrower_id }
    });

    commit("SET_REQUEST_ID", response.data.data);
  },

  async editRequest(_, request) {
    await MicrowdAPI({
      action: "editRequest",
      data: request
    });
  }
};

// _____________________________________ MUTATIONS _____________________________________
const mutations = {
  SET_REQUEST_ID(state, data) {
    state.id = data;
  },

  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
