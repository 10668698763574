import store from "@/store";
import snackbar_messages from "./snackbar_messages";

import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFunctions, httpsCallable } from "firebase/functions";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const functions = getFunctions(app, "europe-west2");

const MicrowdAPI = async (params) => {
  store.commit("SET_LOADING", true);
  const MicrowdAPI = httpsCallable(functions, "MicrowdAPI");

  try {
    const response = await MicrowdAPI(params);

    if (response.data.error) {
      console.log(response.data.errors);
      throw new Error(response.data.message);
    }
    if (params.show_snackbar)
      store.commit("SET_SNACKBAR", {
        show: true,
        text: snackbar_messages[response.data.code] || response.data.message,
        color: "success"
      });

    return response;
  } catch (error) {
    console.log(params.action, error.message);
    store.commit("SET_SNACKBAR", { show: true, text: error.message, color: "error" });
  } finally {
    store.commit("SET_LOADING", false);
  }
};

const uploadToStorage = async (params) => {
  store.commit("SET_LOADING_LISTENER", true);
  const storageRef = storage.ref().child(params.child);

  try {
    await storageRef.put(params.file);
  } catch (error) {
    store.commit("SET_LOADING_LISTENER", false);
    store.commit("SET_SNACKBAR", { show: true, text: error.message, color: "error" });
  }
};

export { MicrowdAPI, uploadToStorage };
