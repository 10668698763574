import "@/firebase";
import axios from "axios";

// _____________________________________ STATE _____________________________________
const getDefaultState = () => {
  return {
    offices: [],
    countries: [],
    cities: [],
    sectors: []
  };
};
const state = getDefaultState();

// _____________________________________ ACTIONS _____________________________________
const actions = {
  // Get the list of office by borrower id
  async getOfficesByCountry({ commit, rootGetters }) {
    const borrower_country = rootGetters["borrower/getBorrowerCountry"];

    try {
      const response = await axios({
        method: "post",
        url: `${process.env.VUE_APP_ELASTIC_URL}/api/office`,
        headers: { ELASTIC_SERVICE_SECRET_KEY: "secret" },
        data: { filter: { ["country.id"]: borrower_country } }
      });

      commit("SET_OFFICES", response.data.data);
    } catch (error) {
      console.log(error);
    }
  },

  // Get list of all countries
  async getCountries({ commit }, data) {
    commit("SET_COUNTRIES", []);

    try {
      const response = await axios({
        method: "post",
        url: `${process.env.VUE_APP_ELASTIC_URL}/api/country`,
        headers: { ELASTIC_SERVICE_SECRET_KEY: "secret" },
        data
      });

      const countriesSorted = response.data.data.sort(sortArray);
      commit("SET_COUNTRIES", countriesSorted);
    } catch (error) {
      console.log(error);
    }
  },

  // Get list of all cities
  async getCities({ commit }, data) {
    commit("SET_CITIES", []);

    try {
      const response = await axios({
        method: "post",
        url: `${process.env.VUE_APP_ELASTIC_URL}/api/city`,
        headers: { ELASTIC_SERVICE_SECRET_KEY: "secret" },
        data
      });

      const citiesSorted = response.data.data.sort(sortArray);
      commit("SET_CITIES", citiesSorted);
    } catch (error) {
      console.log(error);
    }
  },

  // Get list of sectors
  async getSectors({ commit }) {
    commit("SET_SECTORS", []);

    try {
      const response = await axios({
        method: "post",
        url: `${process.env.VUE_APP_ELASTIC_URL}/api/sector`,
        headers: { ELASTIC_SERVICE_SECRET_KEY: "secret" }
      });

      commit("SET_SECTORS", response.data.data);
    } catch (error) {
      console.log(error);
    }
  }
};

// _____________________________________ MUTATIONS _____________________________________
const mutations = {
  SET_OFFICES(state, data) {
    state.offices = data;
  },

  SET_COUNTRIES(state, countries) {
    state.countries = countries;
  },

  SET_CITIES(state, cities) {
    state.cities = cities;
  },

  SET_SECTORS(state, sectors) {
    state.sectors = sectors;
  }
};

function sortArray(x, y) {
  return x.name.localeCompare(y.name);
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
